/**
 * @file: routes/user
 * @author sunwen05
 */

import loadable from 'react-loadable';
import {Spin} from 'skd';

const Loading = Spin;

const userRouters = [
    {
        path: '/login',
        exact: true,
        component: loadable({
            loader: () =>
                import(/* webpackChunkName: 'user' */ /* webpackMode: "lazy" */ '@views/author/login'),
            loading: Loading
        })
    },
    {
        path: '/register',
        exact: true,
        component: loadable({
            loader: () =>
                import(/* webpackChunkName: 'user' */ /* webpackMode: "lazy" */ '@views/author/register'),
            loading: Loading
        })
    }
];

export default userRouters;
