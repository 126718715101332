import React, {createContext} from 'react';
import {ConfigProvider} from 'skd';
import defaultLocaleData from './default';

import zhCN from './zh_CN';
import jaJP from './ja_JP';

const {ConfigContext} = ConfigProvider;

const LocaleContext = createContext({
    'zh-cn': zhCN,
    'ja': jaJP
});

export function useLocaleReceiver(componentName, defaultLocale) {
    const { locale } = React.useContext(ConfigContext);

    const appLocale = React.useContext(LocaleContext);

    const componentLocale = React.useMemo(() => {
        const _locale = defaultLocale || defaultLocaleData[componentName || 'global'];
        const localeFromContext = componentName && appLocale ? appLocale[locale.locale][componentName] : {};

        return {
            ...(typeof _locale === 'function' ? _locale() : _locale),
            ...(localeFromContext || {}),
        };
    }, [componentName, defaultLocale, appLocale]);
    // console.log(locale, appLocale, componentLocale)
    return [componentLocale];
}

export function useGetLocal() {
    const { locale } = React.useContext(ConfigContext);

    return locale?.locale;
}
