/**
 * @file 布局文件
 * @author jianghaoran01
 */
import React from 'react';
import {Layout} from 'skd';
import {withRouter} from 'react-router-dom';
import LayoutMain from '../layout-main';
import AppHeader from '@/components/app-header';
import LeftMenu from '@/components/left-menu';
import './index.less';

const PREFIX = 'primary-layout';

const PrimaryLayout = props => {
    const {history} = props;

    return (
        <>
            {history?.location?.pathname === '/login' ? (
                <LayoutMain />
            ) : (
                <Layout className={PREFIX}>
                    <div className={`${PREFIX}-top`}>
                        <AppHeader {...props} />
                    </div>
                    <div className={`${PREFIX}-left`}>
                        <LeftMenu />
                    </div>
                    <div className={`${PREFIX}-content`}>
                        <LayoutMain />
                    </div>
                </Layout>
            )}
        </>
    );
};

export default withRouter(PrimaryLayout);
